<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div class="control">
                  <label class="radio">
                    <input
                      type="radio"
                      name="metric"
                      value="metric"
                      v-model="metric"
                      v-on:change="clear"
                    />
                    Metric
                  </label>
                  <label class="radio">
                    <input
                      type="radio"
                      name="imperial"
                      value="imperial"
                      v-model="metric"
                      v-on:change="clear"
                    />
                    Imperial
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <aside class="menu">
                  <p class="menu-label">
                    <span class="icon"
                      ><i class="fad fa-balance-scale-left"></i></span
                    ><span>Weight</span>
                  </p>
                  <div class="field">
                    <label class="label"
                      >Weight in
                      {{ metric == "metric" ? "kilograms" : "stone" }}</label
                    >
                    <div class="control has-icons-left">
                      <input
                        class="input"
                        type="number"
                        placeholder="0"
                        v-model="inputW"
                      />
                      <span class="icon is-small is-left">
                        <i class="fad fa-balance-scale-right"></i>
                      </span>
                      <!-- <span class="icon is-small is-right">
                        <i class="fad fa-check"></i>
                      </span> -->
                    </div>
                    <!-- <p class="help">Enter the weight in an inferior measure (Kilograms)</p> -->
                  </div>
                  <div class="field">
                    <p class="control">
                      <button class="button is-primary" @click="convertW">
                        Convert
                      </button>
                    </p>
                  </div>
                </aside>
                <hr />
                <div v-if="convertedW != null">
                  <h2 class="is-subtitle">That's {{ convertedW }} Mitzis!</h2>

                  <h2 class="is-title">
                    <span
                      v-for="i in Math.floor(convertedW)"
                      v-bind:key="i"
                      class="icon is-large"
                    >
                      <i class="fad fa-dog fa-2x"></i>
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <aside class="menu">
                  <p class="menu-label">
                    <span class="icon"
                      ><i class="fad fa-ruler-vertical"></i></span
                    ><span>Height</span>
                  </p>
                  <div class="field">
                    <label class="label"
                      >Height in
                      {{ metric == "metric" ? "cm" : "inches" }}</label
                    >
                    <div class="control has-icons-left">
                      <input
                        class="input"
                        type="number"
                        placeholder="0"
                        v-model="inputH"
                      />
                      <span class="icon is-small is-left">
                        <i class="fad fa-ruler-vertical"></i>
                      </span>
                      <!-- <span class="icon is-small is-right">
                        <i class="fad fa-check"></i>
                      </span> -->
                    </div>
                    <!-- <p class="help">Enter the height in an inferior measure (Inches)</p> -->
                  </div>
                  <div class="field">
                    <p class="control">
                      <button class="button is-primary" @click="convertH">
                        Convert
                      </button>
                    </p>
                  </div>
                </aside>
                <hr />
                <div v-if="convertedH != null">
                  <h2 class="is-subtitle">
                    That's {{ convertedH }} Mitzis tall!
                  </h2>

                  <h2 class="is-title">
                    <span
                      v-for="i in Math.floor(convertedH)"
                      v-bind:key="i"
                      class="icon is-large"
                    >
                      <i class="fad fa-dog fa-2x"></i>
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Weight",
  props: {},
  data() {
    return {
      convertedW: null,
      inputW: null,
      convertedH: null,
      inputH: null,
      metric: "metric",
    };
  },
  mounted() {},
  computed: {},
  methods: {
    clear() {
      this.convertedW = null;
      this.inputW = null;
      this.convertedH = null;
      this.inputH = null;
    },
    convertW() {
      console.log("Converting");
      if (this.metric == "metric") {
        this.convertedW = Math.round((this.inputW / 2.4) * 100) / 100;
      } else {
        this.convertedW = Math.round((this.inputW / 0.37793563636) * 100) / 100;
      }
    },
    convertH() {
      console.log("Converting");
      if (this.metric == "metric") {
        this.convertedH = Math.round((this.inputH / 17.78) * 100) / 100;
      } else {
        this.convertedH = Math.round((this.inputH / 7) * 100) / 100;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
