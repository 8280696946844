<template>
  <div id="app">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="container">
        <div class="navbar-brand">
          <a class="navbar-item" href="/">
            <img src="/images/favicon.png" width="28" height="28" />
          </a>
          <a class="navbar-item"> Mitzi Scales </a>
          <a
            role="button"
            :class="`navbar-burger burger ${open ? 'is-active' : ''}`"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            @click="() => (this.open = !this.open)"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="navbarBasicExample"
          :class="`navbar-menu ${open ? 'is-active' : ''}`"
        >
          <div class="navbar-start">
            <!-- <a class="navbar-item">
              Discordia
            </a> -->
          </div>

          <div class="navbar-end"></div>
        </div>
      </div>
    </nav>
    <section class="section" v-if="message">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <h1 class="title has-text-danger has-text-centered">
                  {{ message }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Mitzi measurements</h1>
          <h2 class="subtitle">What better way to measure anything?</h2>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="columns" style="margin-top: 2rem">
        <div class="column is-8">
          <Weight />
        </div>
        <div class="column is-4">
          <figure class="image">
            <img class="shadow" src="/images/mitzi.png" />
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Weight from "./components/Weight";

export default {
  name: "App",
  components: {
    Weight,
  },
  data() {
    return {
      open: false,
    };
  },
};
</script>

<style lang="scss">
$primary: #8ada72;

@import "../node_modules/bulma/bulma.sass";
// html {
//   background: url("/images/discordia_alt.jpg");
//   background-position: 50%, 50%;
//   background-attachment: fixed;
// }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #686868;
  margin: 1em 0;
  padding: 0;
}

.shadow {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
}

.card,
.navbar {
  background-color: #f8f8f8;
  // color: hsl(0, 0%, 96%);

  // .title,
  // .subtitle {
  //   color: hsl(0, 0%, 96%);
  // }

  // .menu-label,
  // .navbar-item {
  //   color: hsl(0, 0%, 96%);
  // }

  .menu-list {
    text-transform: capitalize;

    .is-active {
      background-color: #f8f8f8;
    }

    // a {
    //   color: hsl(0, 0%, 96%);

    //   &:hover {
    //     &:not(.is-active) {
    //       background-color: #4a4c50;
    //     }
    //   }
    // }
  }
}
</style>
